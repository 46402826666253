import React, { Component } from "react"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"

function CustomAlert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

class Alert extends Component {
  state = {
    severity: "info",
    open: false,
    message: null,
  }

  showMessage = message => {
    this.setState({
      open: true,
      message,
    })
  }

  showError = message => {
    this.setState(
      {
        severity: "error",
      },
      () => {
        this.showMessage(message)
      }
    )
  }

  showWarning = message => {
    this.setState(
      {
        severity: "warning",
      },
      () => {
        this.showMessage(message)
      }
    )
  }

  showInfo = message => {
    this.setState(
      {
        severity: "info",
      },
      () => {
        this.showMessage(message)
      }
    )
  }

  showSuccess = message => {
    this.setState(
      {
        severity: "success",
      },
      () => {
        this.showMessage(message)
      }
    )
  }

  clearMessage = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    this.setState({
      open: false,
    })
  }

  render() {
    return (
      <Snackbar
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.clearMessage}
      >
        <CustomAlert severity={this.state.severity} onClose={this.clearMessage}>
          {this.state.message}
        </CustomAlert>
      </Snackbar>
    )
  }
}

export default Alert
