import React from "react"

import CircularProgress from "@material-ui/core/CircularProgress"

import { makeStyles } from "@material-ui/core/styles"
import { blue } from "@material-ui/core/colors"

const useStyles = makeStyles(theme => ({
  buttonProgress: {
    color: blue[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
}))

const LoadingWrapper = props => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      {props.children}
      {props.loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  )
}

export default LoadingWrapper
