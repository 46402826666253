import React from "react"

import Avatar from "@material-ui/core/Avatar"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ImageIcon from "@material-ui/icons/Image"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import IconButton from "@material-ui/core/IconButton"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import AssignmentIcon from "@material-ui/icons/Assignment"

const RecentShortenedUrls = ({ shortedUrls, removeShortedUrlById }) => {
  const removeItem = id => {
    if (removeShortedUrlById) {
      removeShortedUrlById(id)
    }
  }

  const copyToClipboard = url => {
    console.info(url)
    document.execCommand(url)
  }

  return (
    <>
      <Typography variant="h5" component="h6">
        Recent shortened urls
      </Typography>

      <List>
        {shortedUrls.map((s, i) => {
          return (
            <ListItem key={i} button component="a" href={s.url} target="_blank">
              <ListItemAvatar>
                <Avatar href={s.url}>
                  <ImageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={s.id} secondary={s.longUrl} />

              <ListItemSecondaryAction>
                <IconButton
                  aria-label="copy short url to clipboard"
                  title="copy short url to clipboard"
                  onClick={() => copyToClipboard(s.url)}
                >
                  <AssignmentIcon />
                </IconButton>
                <IconButton
                  aria-label="remove item from list"
                  title="remove item from list"
                  onClick={() => removeItem(s.id)}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>
    </>
  )
}

export default RecentShortenedUrls
