import React, { Component, createRef } from "react"

// import { makeStyles } from "@material-ui/core/styles"
import { withStyles } from "@material-ui/core/styles"

import Box from "@material-ui/core/Box"
import InputAdornment from "@material-ui/core/InputAdornment"
import Paper from "@material-ui/core/Paper"
import InputBase from "@material-ui/core/InputBase"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import HttpIcon from "@material-ui/icons/Http"
import BuildIcon from "@material-ui/icons/Build"
import LoadingWrapper from "../components/LoadingWrapper"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

import Alert from "../components/Alert"

import validator from "validator"
import axios from "axios"

const useStyles = theme => ({
  root: {
    padding: "5px",
    display: "flex",
    alignItems: "center",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  httpIcon: {
    margin: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  spacing: {
    margin: "10px",
  },
  inlineBlock: {
    display: "inline-block",
  },
})

class CreateShortedLink extends Component {
  state = {
    url: "",
    shortedUrls: [],
    open: false,
    loading: false,
  }

  alertRef = createRef()

  render() {
    const { classes } = this.props

    const createUrl = async e => {
      e.preventDefault()

      if (!this.state.loading) {
        if (!validator.isURL(this.state.url)) {
          this.alertRef.current.showWarning("Please type a correct url")
        } else {
          this.setState({
            loading: true,
          })

          const _this = this

          await axios
            .get(`/api/create?longUrl=${encodeURIComponent(this.state.url)}`)
            .then(function (response) {
              const urls = _this.state.shortedUrls.slice()
              if (!urls.find(w => w.id === response.data.id)) {
                urls.push({
                  id: response.data.id,
                  url: window.location.origin + "/" + response.data.id,
                  longUrl: response.data.longUrl,
                })
                _this.setState({
                  shortedUrls: urls,
                })
              }

              _this.setState({
                url: "",
              })
              _this.alertRef.current.showSuccess(
                "The url was shortned with success"
              )
              if (_this.props) {
                _this.props.setShortedUrls(urls)
              }
            })
            .catch(function (error) {
              _this.alertRef.current.showError(
                "We were unable to create the shorted URL at this time, please try again later"
              )

              console.error(error)
            })
            .finally(() => {
              this.setState({
                loading: false,
              })
            })
        }
      }
    }

    return (
      <>
        <Typography variant="h4" component="h1">
          Shorter yours urls for free
        </Typography>

        <Paper component="form" className={classes.root}>
          <HttpIcon className={classes.httpIcon} aria-label="http" />
          <Divider className={classes.divider} orientation="vertical" />

          <InputBase
            className={classes.input}
            placeholder="Paste long url"
            inputProps={{ "aria-label": "paste long url" }}
            value={this.state.url}
            onChange={e => this.setState({ url: e.target.value })}
          />
          <Divider className={classes.divider} orientation="vertical" />
          <LoadingWrapper loading={this.state.loading}>
            <IconButton
              type="submit"
              color="primary"
              className={classes.iconButton}
              aria-label="search"
              disabled={this.state.loading}
              onClick={createUrl}
            >
              <BuildIcon />
            </IconButton>
          </LoadingWrapper>
        </Paper>

        <Box textAlign="right" className={classes.spacing}>
          <Typography
            variant="body1"
            className={`${classes.spacing} ${classes.inlineBlock}`}
          >
            or use a vanity link.
          </Typography>

          <TextField
            id="standard-start-adornment"
            title="It is only for registered users"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  https://urlbaby.com/
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Alert ref={this.alertRef}></Alert>
      </>
    )
  }
}

export default withStyles(useStyles)(CreateShortedLink)
