import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CreateShortedLink from "../parts/CreateShortedLink"
import RecentShortenedUrls from "../parts/RecentShortenedUrls"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    if (typeof window !== "undefined") {
      const shortedUrls = localStorage.getItem("shortedUrls")
      this.state = {
        shortedUrls: shortedUrls ? JSON.parse(shortedUrls) : [],
      }
    } else {
      this.state = {
        shortedUrls: [],
      }
    }
  }

  render() {
    const removeShortedUrlById = id => {
      const index = this.state.shortedUrls.findIndex(w => w.id === id)
      if (index > -1) {
        const urls = this.state.shortedUrls.slice()
        urls.splice(index, 1)
        localStorage.setItem("shortedUrls", JSON.stringify(urls))
        this.setState({
          shortedUrls: urls,
        })
      }
    }

    const setShortedUrls = shortedUrls => {
      const urls = this.state.shortedUrls.slice()
      console.info(urls)
      for (var item of shortedUrls) {
        console.info(item)

        if (urls.findIndex(w => w.id === item.id) === -1) {
          urls.push(item)
        }
      }

      localStorage.setItem("shortedUrls", JSON.stringify(urls))

      this.setState({
        shortedUrls: urls,
      })
    }

    return (
      <Layout>
        <SEO title="Home" />

        <CreateShortedLink setShortedUrls={setShortedUrls}></CreateShortedLink>
        <RecentShortenedUrls
          shortedUrls={this.state.shortedUrls}
          removeShortedUrlById={removeShortedUrlById}
        ></RecentShortenedUrls>

        {/* 
          <p>
            <Link to="/sdfsdafasdf">real 404</Link>
          </p>
          <p>
            <Link to="/NotFound">NF</Link>
          </p>
          <p>
            <Link to="/404">static 404</Link>
          </p>
          <p>
            <Link to="/No6VkJfXH">No6VkJfXH</Link>
          </p>
          <p>
            <Link to="/_n2LXs1aa">http://localhost:8000/_n2LXs1aa</Link>
          </p> */}
      </Layout>
    )
  }
}

export default IndexPage
